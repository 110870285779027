import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root',
})

export class UserService {
   private baseURL;
   bearerToken;

    constructor(private http: HttpClient,
        private authService:AuthService) {
         this.baseURL = environment.apiBaseUrl;
    }

   generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
         var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
         return v.toString(16);
      });
   }

   getToken(userData): Observable<any> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      const url = `${this.baseURL}/user`;
      return this.http.post<any>(url, userData, options)
         .pipe(
            map(res => {
               if (res.Token) {
                  this.authService.isLoggedIn = true;
                  if (!sessionStorage.getItem('tabId')) {
                     sessionStorage.setItem('tabId', this.generateUUID());
                  }
                  const tabId = sessionStorage.getItem('tabId');
                  const key = `authToken-${tabId}`;
                  const sessionIdKey =`UniqueSessionId-${tabId}`; 
                  localStorage.setItem(key, res.Token);
                  localStorage.setItem(sessionIdKey, res.TokenUniqueGUID);
                  return res;
               }
               else {
                  this.authService.isLoggedIn = false;
               }
            }),
            catchError(this.handleError)
         );
   }

   updateSwitchedMarketId(marketId): Observable<any> {
       const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      const url = `${this.baseURL}/user/UpdateDefaultMarketId?switchedMarketId=${marketId}`;
      return this.http.post<any>(url, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
     }

   clearToken(): Observable<any> {
      
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      const url = `${this.baseURL}/User/AddLogout`;
      return this.http.post<any>(url,options)
      .pipe(
        map(res => {
         const response = res as any;
         localStorage.removeItem('token');
         this.authService.isLoggedIn = false;
         return response;
        }),
        catchError(this.handleError)
      );
      
    }

    ResetPassword(userData): Observable<any> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      const url = `${this.baseURL}/User/ResetPassword`;
      return this.http.post<any>(url, userData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
   }

   GetCurrentUser(): Observable<any> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      const url = `${this.baseURL}/user/GetCurrentUser`;
      return this.http.post<any>(url, options)
         .pipe(
            map(res => {
               const user = res as any;
               return user;
            }),
            catchError(this.handleError)
         );
   }

   checkNegetiveCashApprovalForUserGroup(userGroupId): Observable<any> {
      return this.http.get<any>(
         `${this.baseURL}/user/CheckNegetiveCashApprovalForUserGroup?userGroupId=${userGroupId}`,
         { observe: 'response' })
         .pipe(
            map(res => {
               return res.body as any;
            }),
            catchError(this.handleError)
         );
   }

   InsertMonthEndDates(): Observable<any> {
      return this.http.get<any>(
         `${this.baseURL}/user/InsertMonthEndDates`,
         { observe: 'response' })
         .pipe(
            map(res => {
               return res.body as any;
            }),
            catchError(this.handleError)
         );
   }

   getNegetiveCashApprovalUsers(): Observable<any> {
      return this.http.get<any>(
         `${this.baseURL}/user/GetNegetiveCashApprovalUsers`,
         { observe: 'response' })
         .pipe(
            map(res => {
               return res.body as any;
            }),
            catchError(this.handleError)
         );
   }

   authenticatePasscodeForNegetiveCash(userId, passcode): Observable<any> {
      return this.http.get<any>(
         `${this.baseURL}/user/AuthenticatePasscodeForNegetiveCash?userId=${userId}&Passcode=${passcode}`,
         { observe: 'response' })
         .pipe(
            map(res => {
               return res.body as any;
            }),
            catchError(this.handleError)
         );
   }

   private handleError(error: HttpErrorResponse) {
      if (error.error instanceof Error) {
         const errMessage = error.error.message;
         return throwError(errMessage);
      }
      return throwError(error || 'Node.js server error');
   }
}
